import React from "react";
import { useLocation } from "@reach/router";
import { graphql } from "gatsby";
import HeroSectionComponent from "../components/HeroSectionComponent";
import Contentwithoutsb from "../components/Contentwithoutsb";
import BlockBrandList from "../components/BlockBrandList";
import BlockLatestProductList from "../components/BlockLatestProductList";
import ProductRange from "../components/ProductRange";
import Seo from "../components/SEO";

export default function OurBrands({ data }) {
  const productList = data.productbybrand.brand;
  const brandList = data.brands.nodes;

  return (
    <>
      <Seo
        title={data.ourBrandsPage.hero_title}
        description={data.ourBrandsPage.hero_text}
        location={useLocation()}
      />
      <HeroSectionComponent
        title={data.ourBrandsPage.hero_title}
        text={data.ourBrandsPage.hero_text}
        heroImage={false}
        isProductPage={true}
        heroBackgroundImage={
          data.ourBrandsPage.hero_background_image.asset.gatsbyImageData
        }
      />
      <Contentwithoutsb>
        <BlockBrandList
          smallVersion={true}
          brandList={brandList}
          productList={productList}
          borderColor='var(--grey-light)'
        />
      </Contentwithoutsb>
      <BlockLatestProductList
        title='latest products'
        link='/our-products'
        linkText='view all products'
        borderColor='var(--grey-light)'
        smallVersion={false}
      />
      <Contentwithoutsb>
        <ProductRange />
      </Contentwithoutsb>
    </>
  );
}

export const query = graphql`
  query AllProductQuery {
    productbybrand: allSanityProduct(
      sort: { fields: _createdAt, order: [DESC] }
    ) {
      brand: group(field: product_brand___brand_name) {
        fieldValue
        nodes {
          id
          product_slug {
            current
          }
          product_name
          product_chinese_name
          product_brand {
            brand_name
          }
          product_code
          product_info

          product_image {
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }

    brands: allSanityBrand(sort: { fields: brand_name, order: [ASC] }) {
      nodes {
        id
        brand_name
        brand_description
        brand_image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        brand_slug {
          current
        }
      }
    }

    ourBrandsPage: sanityOurBrandsPage {
      hero_text
      hero_title
      hero_background_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
