import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import ProductCard from "./ProductCard";

const BrandListWrapper = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
`;

const BrandListItemWrapper = styled.li`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px dashed var(--black);
  padding-bottom: 0.4rem;
  margin-bottom: 4rem;

  @media (min-width: 768px) {
    padding-bottom: 2rem;
  }

  a {
    margin-bottom: 1rem;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }
  }
`;

const BrandListLogoWrapper = styled.div`
  align-self: flex-start;
  display: flex;
  width: 50%;
  background: var(--white);
  border-radius: var(--border-regular);
  border: 2px solid var(--grey-dark);
  overflow: hidden;
  margin-bottom: 1rem;
  margin-right: 2rem;
  transition: border-color 0.4s ease;

  @media (min-width: 768px) {
    width: 10rem;
    height: 8rem;

    &:hover {
      border-color: var(--black);
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const BrandListTextWrapper = styled.div`
  width: 50%;
  margin-bottom: 1rem;
  padding-top: 0.6rem;

  @media (min-width: 768px) {
    width: 100%;
  }

  h5 {
    text-transform: uppercase;
    font-size: 2.1rem;
  }

  p {
    font-size: 1.2rem;

    @media (min-width: 768px) {
      margin-top: 0.8rem;
      font-size: 1.6rem;
    }
  }
`;

const BrandListTextLogoWrapper = styled.div`
  display: flex;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

const BrandListButton = styled(Link)`
  align-items: center;
  display: flex;
  background-color: var(--black);
  color: var(--white);
  justify-content: center;
  padding-bottom: 2.8rem;
  padding-left: 2.8rem;
  padding-right: 2.8rem;
  padding-top: 2.8rem;
  font-family: var(--font-oswald-regular);
  font-size: 1.6rem;
  text-transform: uppercase;
  border-radius: var(--border-regular);
  transition: background-color 0.25s ease;
  width: 100%;

  @media (min-width: 768px) {
    height: 100%;

    &:hover {
      background-color: var(--red-dark);
    }
  }
`;

const BrandSelectedProductList = styled.ul`
  display: flex;
  overflow-x: scroll;
  padding-bottom: 0px;
  padding-left: 4.5rem;
  padding-right: 0px;
  padding-top: 0px;
  list-style: none;
  margin: 0px;
  width: calc(100% + 8rem);
  margin-left: -5.6rem;
  margin-right: -2.4rem;
  position: relative;
  height: 16rem;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    width: 100%;
    grid-auto-rows: 1fr;
    overflow: initial;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    height: auto;
    min-height: 16rem;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 12rem;
  }

  li {
    flex: 1 0 100%;
    margin-right: 1rem;
    max-width: 31.2rem;

    @media (min-width: 768px) {
      max-width: initial;
      margin-right: 0px;
    }

    &:last-child {
      max-width: 12rem;
      @media (min-width: 768px) {
        max-width: initial;
      }

      a {
        height: 100%;
        max-height: 15rem;
        @media (min-width: 1024px) {
          max-width: 12rem;
        }
      }
    }
  }
`;

const EmptyProductList = styled.div`
  position: absolute;
  left: 56px;
  margin: auto;
  background: var(--grey-dark);
  width: calc(100% - 8rem);
  display: flex;
  height: 15rem;
  padding: 15px;
  text-align: center;
  justify-content: center;
  flex: 1;
  max-width: initial !important;
  align-items: center;

  @media (min-width: 768px) {
    left: 0px;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    padding-left: 2.4rem;
  }
`;

export default function BlockBrandList({
  borderColor,
  smallVersion,
  brandList,
  productList,
}) {
  const brandProductListKey = [];
  productList.map((brandName) =>
    brandProductListKey.push(brandName.fieldValue)
  );

  return (
    <BrandListWrapper>
      {brandList.map((brand) => (
        <BrandListItemWrapper key={brand.id}>
          <BrandListTextLogoWrapper>
            <BrandListLogoWrapper>
              <Link to={`/our-brands/${brand.brand_slug.current}`}>
                <GatsbyImage
                  image={brand.brand_image.asset.gatsbyImageData}
                  alt={brand.brand_name}
                />
              </Link>
            </BrandListLogoWrapper>
            <BrandListTextWrapper>
              <h5>{brand.brand_name}</h5>
              <p>{brand.brand_description}</p>
            </BrandListTextWrapper>
          </BrandListTextLogoWrapper>
          <BrandSelectedProductList>
            {productList.map((productQuery) => {
              if (productQuery.fieldValue === brand.brand_name) {
                return productQuery.nodes.slice(0, 3).map((product) => (
                  <li key={product.id}>
                    <ProductCard
                      itemImage={product.product_image.asset.gatsbyImageData}
                      itemLink={product.product_slug.current}
                      itemBrand={product.product_brand.brand_name}
                      itemTitle={product.product_name}
                      itemChinese={product.product_chinese_name}
                      itemCode={product.product_code}
                      itemInfo={product.product_info}
                      borderColor={borderColor}
                      smallVersion={smallVersion}
                    />
                  </li>
                ));
              }

              return null;
            })}
            {brandProductListKey.indexOf(brand.brand_name) < 0 ? (
              <EmptyProductList className='emptyProduct'>
                <p>Coming soon</p>
              </EmptyProductList>
            ) : (
              <li>
                <BrandListButton to={`/our-brands/${brand.brand_slug.current}`}>
                  view all
                </BrandListButton>
              </li>
            )}
          </BrandSelectedProductList>
        </BrandListItemWrapper>
      ))}
    </BrandListWrapper>
  );
}
